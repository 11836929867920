import React from 'react'

// Update external links to open in a new tab
const UpdateLinks = class extends React.Component {
  render() {
    if (typeof window !== 'undefined') {
      window.addEventListener('load', function (event){
        var links = document.links;
        for (var i = 0, linksLength = links.length; i < linksLength; i++) {
          if (links[i].hostname !== window.location.hostname) {
            links[i].target = "_blank";
            links[i].setAttribute("rel", "noreferrer");
          }
        }
      })
    }
    return (
      <div></div>
    )
  }
}

export default UpdateLinks