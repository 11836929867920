import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import UpdateLinks from '../components/UpdateLinks'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Navbar from '../components/Navbar'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'


export const BasicPageTemplate = ({ title, content, contentComponent, documents, helmet, }) => {
  const PageContent = contentComponent || Content

  return (
    <div className="is-relative">
      <Navbar />
      <section className="section section--gradient">
        {helmet || ''}
        <UpdateLinks />
        <div className="p-5b p-4-mobile has-background-light">
          <div className="container ml-0 mr-auto pt-5b">
            <h1 className="title is-size-3 has-text-weight-bold is-bold-light is-gray">
              {title}
            </h1>
            <PageContent className="content" content={content} />
            {documents && documents.length > 0 &&
              <div className="columns is-multiline is-mobile is-justify-content-start">
                {documents.map((document, index) => (
                  <div key={index} className="column is-half-mobile is-one-third-tablet mb-3">
                    <a href={document.document?.publicURL} target="_blank" rel="noreferrer" className="is-block grow mx-auto" style={{maxWidth: "18rem" }}>
                      <div className="is-relative has-background-white p-2" style={{ maxHeight: '12.5rem' }}>
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: document.thumbnail,
                            alt: document.title,
                          }}
                          className="mx-auto h-100 max-h-11p5"
                          objectFit="contain"
                          style={{ maxHeight: '11.5rem' }}
                        />
                      </div>
                      <div className="has-text-black has-leading-tight mt-1">
                        {document.title}
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  )
}

BasicPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  documents: PropTypes.array,
}

const BasicPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout location={location}>
      <BasicPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        documents={post.frontmatter.documents}
        helmet={
          <Helmet titleTemplate="%s | Smart About Water">
            {/* TO DO: add site title to template using query */}
            <title>{`${post.frontmatter.seoTitle}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>}
      />
    </Layout>
  )
}

BasicPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BasicPage

export const aboutPageQuery = graphql`
query BasicPage($id: String!) {
  markdownRemark(id: {eq: $id }) {
    html
    frontmatter {
      title
      seoTitle
      description
      documents {
        title
        thumbnail {
          childImageSharp {
            gatsbyImageData
          }
        }
        document {
          publicURL
        }
      }
    }
  }
}
`
